import { defineComponent } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default defineComponent({
    name: "BaseImageSlider",
    components: {
        Splide: Splide,
        SplideSlide: SplideSlide,
    },
    props: {
        product: {
            type: Object,
        },
    },
    setup: function () {
        var mainOptions = {
            gap: "1rem",
            rewind: true,
            pagination: false,
            autoWidth: true,
            // perPage: 1,
            // breakpoints: {
            //   991.99: {
            //     height: 380,
            //   },
            //   574.99: {
            //     heigh: 375,
            //   },
            //   280.01: {
            //     height: 227,
            //   },
            // },
            type: "fade",
            perPage: 1,
            padding: 0,
            width: "100vw",
            autoplay: true,
            interval: 4000,
        };
        return {
            mainOptions: mainOptions,
        };
    },
});
