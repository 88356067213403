import { __awaiter, __generator } from "tslib";
import { copyToClipboard } from "@/core/helpers/custom";
import { defineComponent, ref } from "vue";
export default defineComponent({
    name: "BaseShareProduct",
    props: {
        fullProductRoute: {
            type: String,
            required: true,
        },
        product: {
            type: Object,
        },
    },
    setup: function () {
        var _this = this;
        var loading = ref({
            copyClipboard: false,
        });
        var socialShare = ref([
            {
                name: "facebook",
                icon: "bi-facebook",
            },
            {
                name: "messenger",
                icon: "bi-messenger",
            },
            {
                name: "telegram",
                icon: "bi-telegram",
            },
        ]);
        var copyUrl = function (url) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.copyClipboard = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, copyToClipboard(url)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        setTimeout(function () {
                            loading.value.copyClipboard = false;
                        }, 250);
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            socialShare: socialShare,
            copyUrl: copyUrl,
            loading: loading,
        };
    },
});
