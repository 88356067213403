// import axiosInstance from "@/core/services/ApiAxios";
import ApiService from "@/core/services/ApiService";

export const getProductList = async (payload) => {
  return await ApiService.query(`/products`, {
    params: payload,
  });
};

export const showProduct = async (id) => {
  return await ApiService.get(`/products/${id}`);
};

export const getRelatedProduct = async (payload) => {
  return await ApiService.query(`/products/${payload.id}/related`, {
    params: payload,
  });
};

export const getPromotionProduct = async (payload) => {
  return await ApiService.get(`/products/promotions`);
};
