import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-b01e015a"), n = n(), _popScopeId(), n); };
var _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_SplideSlide = _resolveComponent("SplideSlide");
    var _component_Splide = _resolveComponent("Splide");
    return (_openBlock(), _createBlock(_component_Splide, {
        "aria-labelledby": "thumbnail-example-heading",
        is: 'section',
        options: _ctx.mainOptions,
        ref: "main",
        class: "mt-3"
    }, {
        default: _withCtx(function () {
            var _a;
            return [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.images, function (slide) {
                    return (_openBlock(), _createBlock(_component_SplideSlide, {
                        key: slide === null || slide === void 0 ? void 0 : slide.image_path
                    }, {
                        default: _withCtx(function () { return [
                            _createElementVNode("img", {
                                src: slide === null || slide === void 0 ? void 0 : slide.image_path,
                                alt: "image",
                                class: "product_height"
                            }, null, 8, _hoisted_1)
                        ]; }),
                        _: 2
                    }, 1024));
                }), 128))
            ];
        }),
        _: 1
    }, 8, ["options"]));
}
