import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_icon = _resolveComponent("base-icon");
    var _component_ShareNetwork = _resolveComponent("ShareNetwork");
    var _component_base_flex = _resolveComponent("base-flex");
    return (_openBlock(), _createBlock(_component_base_flex, null, {
        default: _withCtx(function () { return [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialShare, function (social) {
                var _a, _b, _c, _d;
                return (_openBlock(), _createBlock(_component_ShareNetwork, {
                    key: social.name,
                    network: social.name,
                    url: _ctx.fullProductRoute,
                    title: (_b = (_a = _ctx.product) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Undefine',
                    description: (_c = _ctx.product) === null || _c === void 0 ? void 0 : _c.description,
                    quote: (_d = _ctx.product) === null || _d === void 0 ? void 0 : _d.description,
                    hashtags: "",
                    class: "ms-2"
                }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_base_icon, {
                            iconName: "bi " + social.icon,
                            isGreenBackground: ""
                        }, null, 8, ["iconName"])
                    ]; }),
                    _: 2
                }, 1032, ["network", "url", "title", "description", "quote"]));
            }), 128)),
            _createVNode(_component_base_icon, {
                iconName: "bi bi-link-45deg",
                "data-clipboard-text": _ctx.fullProductRoute,
                onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.copyUrl(_ctx.fullProductRoute)); }),
                showLoading: _ctx.loading.copyClipboard,
                class: "ms-2",
                isGreenBackground: ""
            }, null, 8, ["data-clipboard-text", "showLoading"])
        ]; }),
        _: 1
    }));
}
